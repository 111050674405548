import React from 'react';
import { useState, useCallback } from 'react';
import Modal from 'react-modal';
import { modalWindowStyle } from '../../styles/styles';
import { backfaceFixed } from '../../utils/backfaceFixed';
import { css } from '@emotion/react';
import * as Styles from '../../styles/styles';

// root nodeのSelectorを設定
Modal.setAppElement('#___gatsby');

export interface ModalParam {
  id: String;
}

const ModalWindow: React.FC<ModalParam> = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const onClickHandler = useCallback(() => {
    setIsOpen(true);
    backfaceFixed(true);
  }, []);

  const closeHandler = useCallback(() => {
    setIsOpen(false);
    backfaceFixed(false);
  }, []);

  return (
    <div css={Styles.modalWindowStyle}>
      <a className="modal-window_button" onClick={onClickHandler}>
        <span>
          <strong>※新型コロナウィルス感染防止対策に関して</strong>
        </span>
      </a>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeHandler}
        onCloseModal={closeHandler}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        overlayClassName={{
          base: 'overlay-base',
          afterOpen: 'overlay-after',
          beforeClose: 'overlay-before'
        }}
        className={{
          base: 'content-base',
          afterOpen: 'content-after',
          beforeClose: 'content-before'
        }}
        closeTimeoutMS={500}
      >
        <div css={Styles.modalWindowStyle}>
          <div className="modal-window_wrapper">
            <p>
              開催にあたり、新型コロナウイルス感染拡大防止ガイドラインを順守し、実施させていただきます。
              <br />
              お客様に関しましてもご協力の程宜しくお願い致します。
              <br />
              <small>※2022.9.20更新</small>
              <br />
              <a href="/event-checklist.pdf" target="_blank" rel="noopener noreferrer">
                イベント開催時のチェックリスト
              </a>
            </p>
            <button type="button" className="modal-close">
              <span>Close</span>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalWindow;
